import { TextField as KBTextField } from "@kobalte/core/text-field"
import { splitProps, type ComponentProps } from "solid-js"
import { createInput, type FieldAttributes, type StaticError } from "src/utils/forms"
import { styles } from "../../styles"

const validator = (required?: boolean) => (value: string) =>
  required ? (value === "" ? "This field is required." : undefined) : undefined
export function TextField(
  props: ComponentProps<typeof KBTextField> & {
    label?: string
    labelHelper?: string
    multiline?: boolean
    placeholder?: string
    error?: StaticError
    type?: FieldAttributes["type"]
    input?: ReturnType<typeof createInput<typeof props.value>>
  } & Omit<FieldAttributes, "value" | "name" | "type">,
) {
  const [local, others] = splitProps(props, [
    "label",
    "labelHelper",
    "multiline",
    "placeholder",
    "error",
    "type",
    "class",
  ])
  const { value, setValue, validate, setInput, error } =
    props.input ?? createInput<string>(props.value ?? "", validator(others.required), props.error)

  const classes = styles.field({ editable: !props.readOnly && !props.disabled })

  return (
    <KBTextField
      class={classes.root()}
      {...others}
      validationState={error() ? "invalid" : "valid"}
      value={value()}
      onChange={props.onChange ?? setValue}
    >
      {local.label ? (
        <KBTextField.Label class={classes.label()}>
          {local.label}
          {local.labelHelper != undefined ? (
            <span class={classes.labelHelper()}>{local.labelHelper}</span>
          ) : !others.required ? (
            <span class={classes.labelHelper()}>(optional)</span>
          ) : (
            <></>
          )}
        </KBTextField.Label>
      ) : (
        <></>
      )}
      <div class="relative flex w-full gap-4">
        {props.multiline ? (
          <KBTextField.TextArea<any>
            class={classes.input({ class: ["min-h-20", local.class] })}
            placeholder={local.placeholder}
            autoResize
            onBlur={() => validate()}
            ref={setInput}
          >
            {props.value}
          </KBTextField.TextArea>
        ) : (
          <KBTextField.Input
            class={classes.input({ class: [local.class] })}
            placeholder={local.placeholder}
            type={local.type}
            data-1p-ignore
            ref={setInput}
            onBlur={() => validate()}
          />
        )}
        {props.children}
      </div>
      <KBTextField.ErrorMessage class={classes.errorMessage()}>{error()}</KBTextField.ErrorMessage>
    </KBTextField>
  )
}
